@import url("bootstrap-icons/font/bootstrap-icons.css");

main {
    .item {
        text-align: center;
        margin-top: 3rem;

        .block {
            overflow: hidden;
            position: relative;

            .title {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                line-height: 3;
                background-color: rgba(0, 0, 0, .4);
                color: #FFFFFF;
                font-weight: bold;
                font-size: 1.2rem;
            }
        }

        .block:hover {
            cursor: pointer;

            .title {
                background-color: #333333;
            }
        }
    }

    .service {
        text-align: center;

        .title {
            font-size: calc(0.875rem + 1vmin);
            font-weight: bold;
        }

        i {
            font-size: 3rem;
            color: var(--bs-gray-500);
            position: relative;
            left: -2px;
            top: 5px;
        }

        .col-3 {
            cursor: pointer;
        }
    }

    .count {
        text-align: center;

        .col-12 {
            position: relative;
        }

        .title {
            font-size: calc(0.875rem + 2vmin);
            font-weight: bold;
        }

        .info {
            .info-title {
                font-weight: bold;
            }

            .info-details {
                color: var(--bs-gray-600);
            }
        }
    }

    .popular, .brand {
        .title {
            font-size: calc(0.875rem + 1vmin);
            font-weight: bold;

            span {
                font-weight: lighter;
                font-size: 1rem;
                float: right;
                color: var(--bs-gray-500);
            }
        }

        .card {
            overflow: hidden;
        }

        .card:hover {
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        }
    }

    hr.vertical.dark {
        background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, .4), transparent);
    }

    hr.vertical {
        position: absolute;
        background-color: transparent;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
    }
}

header {
    padding: 1rem 0;

    .logo {
        margin-top: 1rem;
        text-align: center;

        img {
            width: calc(100px + 10vmin);
        }
    }

    .title {
        font-size: calc(1rem + 2vmin);
        font-weight: bold;
        text-align: center;
        margin-top: 2rem;
    }
}

footer {

    margin-bottom: 2rem;

    .copyright,
    .company,
    .beian {
        font-size: 12px;
        color: var(--bs-gray-600);
        text-align: center;
        line-height: 2;
    }

    a {
        color: var(--bs-gray-600);
        text-decoration: none;
    }

    a:hover {
        color: #3964e6;
    }
}

.productList {
    padding-top: 2rem;
    text-align: center;

    .block {
        cursor: pointer;

        img {
            margin-bottom: 0.5rem;
        }
    }

    .block:hover {
        color: #3964e6;
        font-weight: bold;
    }

    .action {
        color: #3964e6;
        font-weight: bold;
    }
}

.productInfo {
    padding-top: 1rem;

    .title {
        background-color: var(--bs-gray-200);
        font-weight: bold;
        line-height: 2rem;
        padding-left: 0.5rem;
        border-radius: 5px;
    }

    .list {
        padding: 1rem;
        line-height: 2rem;

        i {
            color: var(--bs-gray-400);
        }

        .col-9 {
            cursor: pointer;
        }

        .col-9:hover i {
            color: var(--bs-gray-dark);
        }
    }
}

.advertise {
    margin-bottom: 1rem;
}